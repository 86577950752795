<template>
  <cmxviewport/>
</template>

<script>
import Viewport from './components/Viewport.vue'

export default {
  name: 'App',
  components: {
    cmxviewport: Viewport,
  }
}
</script>

<style>
#app,html,body {
  height: 100vh;
}

* {
  font-family: sans-serif;
  margin: 0px;
  padding: 0px;
  touch-action: none;
  user-select: none;
  overflow: hidden;
}
</style>
