<script>
export default {
  data() {
    return {
      selected: null,
      items: [
        {name: "spatter", src: "./brush3.png"},
        {name: "soft", src: "./brush2.png"},
        {name: "hard", src: "./brush.png"},
      ]
    };
  },
  methods: {
    select(item) {
      this.selected = item;
      this.$emit("select", item.src);
    },
    isSelected(item) {
      return this.selected == item;
    }
  },
  mounted() {
  }
}
</script>

<template>
  <div class="thumbgrid"> 
    <div v-for="item in items" :key="item.name" :class="{ thumb: true, selected: isSelected(item)}" @click="select(item)">
      <img class="brush-thumb" :src="item.src"/>
    </div>
  </div>
</template>

<style>

.thumbgrid {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 45px 45px 45px 45px;
  grid-auto-rows: 45px;
}

.thumb {
  width: 40px;
  height: 40px;
  border: 2px solid black; 
  background-color: black;
}

.brush-thumb {
  width: 40px;
  height: 40px;
  object-fit: scale-down;
}

.selected {
  border: 2px solid #ffff00; 
}
</style>
