<script>
import Slider from './Slider.vue'
import Preview from './Preview.vue'
import ThumbGrid from './ThumbGrid.vue'
import { CMXViewport, CMXParams } from '../lib/chromx.js'

import * as THREE from 'three';
import { saveAs } from 'file-saver';
import GLTFExporter from './gltf.js';

export default {
  el: "canvas#viewport",
  name: 'cmxviewport',
  components: {
    cmxslider: Slider,
    cmxpreview: Preview,
    cmxthumbgrid: ThumbGrid
  },
  data() {
    return new CMXParams();
  },
  methods: {
    setCurrentBrush: function (ev) {
      this.viewport.loadBrush(ev);
    },

    loadObj: function(path) {
      this.viewport.loadObj(path);
    },

    loadObjFromFile: function (ev) {
      const file = ev.target.files[0];
      this.viewport.loadObj(file.name);
    },

    saveGLTFToFile: function () {
      let current = this.viewport.history.current();

      let color = new THREE.DataTexture(current.color.buf, current.color.target.width, current.color.target.height, THREE.RGBAFormat, THREE.UnsignedByteType);
      color.needsUpdate = true;
      let pbr = new THREE.DataTexture(current.pbr.buf, current.pbr.target.width, current.pbr.target.height, THREE.RGBAFormat, THREE.UnsignedByteType);
      pbr.needsUpdate = true;

      this.viewport.material.map = color;
      this.viewport.material.metalnessMap = pbr;
      this.viewport.material.roughnessMap = pbr;

      // need to convert to normalMap
      //let bump = new THREE.DataTexture(current.bump.buf, current.bump.target.width, current.bump.target.height, THREE.RGBAFormat, THREE.UnsignedByteType);
      this.viewport.material.bumpMap = null;
      this.viewport.material.needsUpdate = true;

      new GLTFExporter().parse( this.viewport.mesh, (gltf) => {
        var blob = new Blob([gltf], {type: "application/octet-stream"});
        saveAs(blob, "chromx.glb");

        this.viewport.material.map = current.color.target.texture;
        this.viewport.material.metalnessMap = current.pbr.target.texture;
        this.viewport.material.roughnessMap = current.pbr.target.texture;
        this.viewport.material.bumpMap = current.bump.target.texture;
        this.viewport.material.needsUpdate = true; //bump;
      }, {binary: true});
    },
    hue(h) {
      let color = new THREE.Color();
      color.setHSL(h, this.brushColorSat, this.brushColorVal);
      return color.getStyle();
    },
    sat(s) {
      let color = new THREE.Color();
      color.setHSL(this.brushColorHue, s, this.brushColorVal);
      return color.getStyle();
    },
    val(v) {
      let color = new THREE.Color();
      color.setHSL(this.brushColorHue, this.brushColorSat, v);
      return color.getStyle();
    },
    alpha(a) {
      let color = new THREE.Color();
      color.setHSL(this.brushColorHue, this.brushColorSat, this.brushColorVal);
      return `rgba(${color.r*255}, ${color.g*255}, ${color.b*255}, ${a*255})`
    },
    showOverlay() {
      this.hasOverlay = !this.hasOverlay;
    },
    showOverlay2() {
      this.hasOverlay2 = !this.hasOverlay2;
    },
    hideOverlay() {
      this.hasOverlay2 = false;
      this.hasOverlay = this.stickyOverlay;
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$refs.container.requestFullscreen().catch(err => {
          console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen();
      }
    }
  },
  computed: {
    previewMaterial: {
      get() {
        let color = new THREE.Color();
        color.setHSL(this.brushColorHue, this.brushColorSat, this.brushColorVal);
        return {
          color: color, 
          alpha: this.brushColorAlpha,
          roughness: this.brushPbrRoughness,
          metalness: this.brushPbrMetalness,
          envMap: this.envTexture,
          bumpMap: this.brushTexture,
        };
      }
    }
  },
  mounted() {
    this.envTexture = new THREE.TextureLoader().load('./environment.jpg');
    this.envTexture.mapping = THREE.EquirectangularReflectionMapping;

    this.viewport = new CMXViewport(this.$refs.viewport, this);

    this.loadObj('./monkey.obj')
    this.$refs.thumbgrid.select(this.$refs.thumbgrid.items[0]);

    document.body.addEventListener("keypress", (e) => {
      if (e.which == 32) {
        e.preventDefault();
        this.hasOverlay = !this.hasOverlay || this.stickyOverlay;
        this.hasOverlay2 = false;
      }

      if (e.which == 63 && e.shiftKey) {
        e.preventDefault();
        this.hasOverlay2 = !this.hasOverlay2;
        this.hasOverlay = false;
      }
    }, false);
    //var lastPoint = null;

  }
}
</script>

<!-- this stuff really should go into separate vue components -->
<template>
  <div ref="container" id="container">
    <canvas ref="viewport" @click="hideOverlay"/> 
    <cmxpreview :class="{ clicky: !hasOverlay, unclicky: hasOverlay, hidden: hasOverlay2 }" v-model="previewMaterial" @click="showOverlay" /> 
    <div :class="{ clicky2: !hasOverlay2, unclicky2: hasOverlay2, hidden: hasOverlay }" @click="showOverlay2" > 
        ?
    </div>
    <div id="overlay" ref="overlay" :class="{ nooverlay: !hasOverlay, overlay: hasOverlay }" > 
      <div id="brush"> 
        <h2> Brush </h2>
        <label>
          Enable Color
          <input type="checkbox" v-model="brushColorEnabled">
        </label>
        <cmxslider label="hue" v-model="brushColorHue" :min=0 :max=1 :style="{
        background: `linear-gradient(to right, ${hue(0)} 0%, ${hue(0.17)} 17%, ${hue(0.33)} 33%, ${hue(0.5)} 50%, ${hue(0.67)} 67%, ${hue(0.83)} 83%, ${hue(1)} 100%)`
        }"/>
        <cmxslider label="sat" v-model="brushColorSat" :min=0 :max=1  :style="{
          background: `linear-gradient(to right, ${sat(0)} 0%,  ${sat(1)} 100%)`
        }"/>
        <cmxslider label="val" v-model="brushColorVal" :min=0 :max=1  :style="{
          background: `linear-gradient(to right, ${val(0)} 0%,  ${val(1)} 100%)`
        }"/>
        <cmxslider label="alpha" v-model="brushColorAlpha" :min=0 :max=1  :style="{
          background: `linear-gradient(to right, ${alpha(0)} 0%, ${alpha(1)} 100%)`
        }"/>
        <p>Brush Size</p>
        <cmxslider label="radius" v-model=brushRadius :min=1 :max=1000 :style="{
          background: `linear-gradient(to right, #ccccdd 0%,  #ffffff33 100%)`
        }"/>
      </div>
      <div id="brush-advanced"> 
        <h2> Advanced </h2>
        <label>
          Enable Rough/Metal
          <input type="checkbox" v-model="brushPbrEnabled">
        </label>
        <cmxslider label=roughness v-model=brushPbrRoughness :min=0 :max=1 :style="{
          background: `linear-gradient(to right, #ccccdd 0%,  #ffffff33 100%)`
        }"/>
        <cmxslider label=metalness v-model=brushPbrMetalness :min=0 :max=1 :style="{
          background: `linear-gradient(to right, #ccccdd 0%,  #ffffff33 100%)`
        }"/>
        <label>
          Enable Bump
          <input type="checkbox" v-model="brushBumpEnabled">
        </label>
        <cmxslider label="bump" v-model=brushBump :min=0 :max=1 :style="{
          background: `linear-gradient(to right, #ccccdd 0%,  #ffffff33 100%)`
        }"/>
        <div id="brushes"> 
          <h2> Brushes </h2>
          <cmxthumbgrid ref="thumbgrid" @select="setCurrentBrush"/>
        </div>
        <label>
          Sticky Overlay
          <input type="checkbox" v-model="stickyOverlay">
        </label>
      </div>
    </div>
    <div id="overlay2" ref="overlay2" :class="{ nooverlay2: !hasOverlay2, overlay2: hasOverlay2 }" > 
      <div id="help"> 
        <h2> Welcome! </h2>
        <p>
        <ul>
          <li>* You can press 'space' or touch the sphere in the upper left corner to modify the current material.</li>
          <li>* You can press '?' or touch the question mark in the upper left corner to bring up this overlay.</li>
          <li>* shift+drag to rotate (yaw or pitch) the object.</li>
          <li>* ctrl+drag to zoom or roll the object </li>
          <li>* shift+click or two-finger tap to undo</li>
          <li>* hold down to color pick</li>
        </ul>
        </p>
        <button @click="toggleFullscreen"> fullscreen </button>
      </div>
      <div id="storage"> 
        <h2> Examples </h2>
        <ul>
          <li><button @click="loadObj('./monkey.obj')">monkey</button></li>
          <li><button @click="loadObj('./cube.obj')">cube</button></li>
          <li><button @click="loadObj('./sphere.obj')">sphere</button></li>
          <li><button @click="loadObj('./simple_ship.obj')">ship</button></li>
        </ul>
        <h2> Import </h2>
        <p> Imported models should be a single-object OBJ, with reasonable UVs and roughly 1-3 units per side.  </p>
        <label class="obj-reader">
          <input type="file" accept=".obj" @change="loadObjFromFile">
        </label>
        <h2> Export </h2>
        <p> Exported models are glTF and include the material.  None of this makes sense. </p>
        <label class="obj-reader">
          <button @click="saveGLTFToFile"> Export File </button>
        </label>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
#viewport {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 10;
}

#overlay {
  backdrop-filter: blur(4px);
  border: 1px solid black;
  border-radius: 3px;
  padding: 20px;
  margin-top: 25px;
  position: absolute;
  top: 0;
  color: white;
  background-color: rgba(30,30,30,0.3);
  z-index: 100;
}

.overlay {
  left: 0px;
  transition: 
    left 0.2s ease-in-out;
}

.nooverlay {
  left: -100vw;
  transition: 
    left 0.1s ease-in-out;
}

#overlay2 {
  backdrop-filter: blur(4px);
  border: 1px solid black;
  border-radius: 3px;
  padding: 20px;
  margin-top: 25px;
  position: absolute;
  top: 0;
  color: white;
  background-color: rgba(30,30,30,0.3);
  z-index: 100;
}

.overlay2 {
  left: 0px;
  transition: 
    left 0.2s ease-in-out;
}

.nooverlay2 {
  left: -100vw;
  transition: 
    left 0.1s ease-in-out;
}

@media screen and (max-height: 600px) {
  #brush {
    float: left;
  }

  #brush-advanced {
    float: left;
  }
}

@media screen and (min-height: 600px) {
  #brush {
  }

  #brush-advanced {
  }
}

#storage {
    width: 300px;
}

#help {
    width: 300px;
}

label {
  font-size: 10pt;
}

input[type='checkbox'] {
  position: relative;
  bottom: -4px;
  width: 20px;
  height: 20px;
}


p {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 8pt;
}

.hidden {
  visibility: hidden;
}

.clicky2 {
  padding: 20px;
  position: absolute;
  z-index: 1;

  width: 50px !important;
  height: 50px !important;

  top: 70px;
  left: 0px;

  transition: 
    z-index 0.1s ease-in-out, 
    padding 0.1s ease-in-out, 
    width   0.1s ease-in-out, 
    height  0.1s ease-in-out, 
    top     0.1s ease-in-out, 
    left    0.1s ease-in-out; 

  color: white;
  font-weight: bold;
  font-size: 40pt;
  text-align: center;
}

.unclicky2 {
  position: absolute;
  z-index: 150;

  top: -5px;
  left: 280px;

  height: 90px !important;
  width: 90px !important;

  transition: 
    z-index  0.2s ease-in-out, 
    padding  0.2s ease-in-out, 
    width    0.2s ease-in-out, 
    height   0.2s ease-in-out, 
    top      0.2s ease-in-out, 
    left     0.2s ease-in-out; 

  color: white;
  font-weight: bold;
  font-size: 60pt;
}

.clicky {
  padding: 20px;
  position: absolute;
  z-index: 1;

  width: 50px !important;
  height: 50px !important;

  top: 0px;
  left: 0px;

  transition: 
    z-index 0.1s ease-in-out, 
    padding 0.1s ease-in-out, 
    width   0.1s ease-in-out, 
    height  0.1s ease-in-out, 
    top     0.1s ease-in-out, 
    left    0.1s ease-in-out; 
}

.unclicky {
  position: absolute;
  z-index: 150;

  top: 10px;
  left: 150px;

  height: 90px !important;
  width: 90px !important;

  transition: 
    z-index  0.2s ease-in-out, 
    padding  0.2s ease-in-out, 
    width    0.2s ease-in-out, 
    height   0.2s ease-in-out, 
    top      0.2s ease-in-out, 
    left     0.2s ease-in-out; 
}

</style>
