<script>
import { CMXPreview } from '../lib/chromx.js'
import { watchEffect } from 'vue';

export default {
  name: 'cmxpreview',
  props: {
    modelValue: {
      type: Object,
      default: null
    },
  },
  methods: {
    onResize() {
      console.log("resize!");
      let preview = this.$el.getBoundingClientRect();
      console.log(preview);
    }
  },
  mounted() {
    this.preview = new CMXPreview(this.$refs.preview);

    watchEffect(() => {
      this.preview.matSphere.material.color = this.modelValue.color;
      this.preview.matSphere.material.opacity = this.modelValue.alpha;
      this.preview.matSphere.material.roughness = this.modelValue.roughness;
      this.preview.matSphere.material.metalness = this.modelValue.metalness;
      this.preview.matSphere.material.envMap = this.modelValue.envMap;
      this.preview.matSphere.material.bumpMap = this.modelValue.bumpMap;
      this.preview.matSphere.material.needsUpdate = true;
    });
  }
}
</script>

<template>
  <canvas ref="preview" @resize="onResize" /> 
</template>

<style scoped>
</style>
