
<script>

import { watchEffect } from 'vue';

/* eslint-disable no-unused-vars */
let linear = function(x) {return x;}; 
let quad = function(x) {return x * x;};
/* eslint-enable no-unused-vars */

function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num;
}

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Number,
      default: 0.5
    },
    min: {
      type: Number,
      default: 0.0
    },
    max: {
      type: Number,
      default: 1.0
    },
    ramp: {
      type: Function,
      default: linear
    },
  },
  data() {
    return {
      currentValue: this.modelValue,
      posX: 0.0,
      posY: 0.0,
    };
  },
  methods: {
    updateFromEvent: function(e) {
      let slider = this.$el.getBoundingClientRect();

      // if we aren't clicking, we aren't picking
      e.stopPropagation();
      if (e.target != this.$el || ("buttons" in e && e.buttons != 1)) {
        return;
      }
      e.preventDefault();

      let ex = ("offsetX" in e) ? e.offsetX : (clamp(e.touches[0].clientX, slider.left, slider.right) - slider.left);

      let x = ex/slider.width;
      let v = this.min + (this.max-this.min)*x;
      this.currentValue = clamp(v, this.min, this.max);
      if (Math.abs(this.currentValue - this.min) < 0.01) this.currentValue = this.min;
      if (Math.abs(this.currentValue - this.max) < 0.01) this.currentValue = this.max;
      this.$emit('update:modelValue', this.currentValue);
    },
  },
  mounted() {
    this.$el.addEventListener("touchmove", this.updateFromEvent, false);
    this.$el.addEventListener("touchstart", this.updateFromEvent, false);
    this.$el.addEventListener("mousedown", this.updateFromEvent, false);
    this.$el.addEventListener("mousemove", this.updateFromEvent, false);
    this.$el.addEventListener("click", this.updateFromEvent, false);

    watchEffect(() => {
      //console.log("watch slider: ", this.modelValue);
      let slider = this.$el.getBoundingClientRect();
      let indicator = this.$refs.indicator.getBoundingClientRect();

      // probably should add some dead space at the edge to make
      // it a bit easier to hit the limits
      let x = this.modelValue/(this.max-this.min) * slider.width;
      this.posX = x - indicator.width/2.0
      this.posY = this.$el.getBoundingClientRect().height/2.0-this.$refs.indicator.getBoundingClientRect().height/2.0;
      //console.log(this.posX, this.posY);
    });
    this.$forceUpdate();
  }
}
</script>

<template>
  <div   class=slider       id=slider>
    <div class=slider-label id=label :style="{top: posY + 'px'}"> {{ label }} {{ modelValue.toPrecision(3) }} </div>
    <div class=slider-thumb id=indicator ref=indicator :style="{left: posX + 'px', top: posY + 'px'}" />
  </div>
</template>

<style>

.slider {
  margin: 15px;
  width:200px;
  height:15px;
}

.slider-thumb {
  width:5px;
  height:20px;
}

#slider {
  z-index: 200;
  position: relative;
  overflow: visible;
  background-color: white;
}

#label {
  font-size: 10px;
  pointer-events: none;
  padding: 4px;
  position: absolute;
  color: white;
  filter: 
    drop-shadow(0px 0px 1px black) 
    drop-shadow(0px 0px 2px black)
    drop-shadow(0px 0px 1px black);
}

#indicator {
  border-radius: 1px;
  position: absolute;
  pointer-events: none;
  background: linear-gradient(to bottom, #999999, #bbbbbb);
}
</style>
