//import Stats from 'stats.js'
import * as THREE from 'three';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2.js';

const vShader = `
varying vec2 v_uv;
varying vec2 v_pos;
void main() {
  v_uv = uv;
  v_pos = vec2(position);
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`

const LONG_DURATION_MS = 150;
const LONG_DISTANCE_PT = 10;



// ortho overlay for rendering various tools and options
export class CMXPreview {
  constructor(root) {
    let bounds = root.getBoundingClientRect();
    let aspect = bounds.width/bounds.height;
    this.renderer = new THREE.WebGLRenderer({ canvas: root, antialias: true, alpha: true });
    this.renderer.autoClear = false;
    this.renderer.setSize( bounds.width, bounds.height);
    this.renderer.setAnimationLoop((t) => { 
      t;
      this.renderer.render( this.scene, this.camera );
    });

    this.scene = new THREE.Scene();

    // simple ortho camera - everything is relative.
    this.camera = new THREE.OrthographicCamera(-aspect,  aspect, 1, -1, 0.1, 100);

    this.light = new THREE.DirectionalLight();
    this.light.intensity = 1;
    this.light.castShadow =  true;
    this.light.position.x = 4;
    this.light.position.y = 4;
    this.light.position.z = 4;
    this.scene.add(this.light);
    
    this.camera.position.z = 3;
    this.scene.add(this.camera);

    this.matSphere = new THREE.Mesh(new THREE.SphereGeometry(1.0, 32, 32), new THREE.MeshStandardMaterial({
      color: "#ff0000",
      transparent: true,
      opacity: 1.0,
      flatShading: false,
      metalness: 0.0,
      roughness: 0.2,
      bumpScale: 0.01,
      envMap: null,
      bumpMap: null
    }));

    this.matSphere.position.x = 0.0;
    this.matSphere.position.y = 0.0;
    this.scene.add(this.matSphere);
  }
}

// a class representing a bunch of buffers for undo/redo
class CMXUndoHistory {
  index = null;
  oldest = null;
  latest = null;
  buffers = [];

  constructor(buffers, renderer) {
    this.buffers = buffers;
    this.reset(renderer);
  }

  reset(renderer) {
    this.index = 0;
    this.oldest = 0;
    this.latest = 0;
    for (let b of this.buffers) {
      b.clear(renderer);
    }
  }

  current() {
    return this.buffers[this.index];
  }

  previous() {
    // if we have something older
    if (this.index != this.oldest) {
      return this.buffers[this.mod(this.index - 1, this.buffers.length)];
    } else {
      return null;
    }
  }

  push() {
    // bump our index
    this.index = this.mod(this.index + 1, this.buffers.length);

    // if we've come up against the oldest, bump that
    if (this.index == this.oldest) {
      this.oldest = this.mod(this.oldest + 1, this.buffers.length);
    }

    // and reset the latest
    this.latest = this.index;
    console.log("push!", this);
  }

  undo() {
    if (this.index != this.oldest) {
      this.index = this.mod(this.index - 1, this.buffers.length);
    }
    console.log("undo!", this);
  }

  redo() {
    if (this.index != this.latest) {
      this.index = this.mod(this.index + 1, this.buffers.length);
    }
    console.log("redo!", this);
  }

  mod(n, m) {
    return ((n % m) + m) % m;
  }
}

class CMXBuffer {
  constructor(w, h, fill) {
    this.recompute = true;
    this.target = new THREE.WebGLRenderTarget(w, h, {alpha: false, autoClear: false});
    this.buf = new Uint8Array(this.target.width*this.target.height*4); 
    this.fill = fill;
  }

  render(renderer, scene, camera, callback) {
    renderer.setRenderTarget(this.target) 
    callback();
    renderer.render(scene, camera);

    if (this.recompute) {
      this.recompute = false;
      console.log("recomputing pick buffer");
      renderer.readRenderTargetPixels(
          this.target,
          0,   // x
          0,   // y
          this.target.width,   // width
          this.target.height,  // height
          this.buf);
    }
  }

  clearBuffer(renderer) {
    renderer.setRenderTarget(this.target) 
    renderer.setClearColor(this.fill);
    renderer.clear()
  }

  pick(x, y) {
    let c = 4*(x + (this.target.width*(this.target.height - y)));
    return new THREE.Color(
      this.buf[c+0]/255.0,
      this.buf[c+1]/255.0,
      this.buf[c+2]/255.0,
    );
  }
}

class CMXSurface {
  constructor(res) {
    let color = new THREE.Color(1.0, 1.0, 1.0);
    let metalness = 0.5;
    let roughness = 1.0;
    let bump = 0.0;
    this.color = new CMXBuffer(res, res, color);
    this.pbr = new CMXBuffer(res, res, new THREE.Color(1.0, metalness, roughness));
    this.bump = new CMXBuffer(res, res, new THREE.Color(bump, bump, bump));
  }

  clear(renderer) {
    console.log("clearing surface.");
    this.color.clearBuffer(renderer);
    this.pbr.clearBuffer(renderer);
    this.bump.clearBuffer(renderer);
  }
}

// eslint-disable-next-line no-unused-vars
export class CMXParams {
  name = "";
  stickyOverlay = false;
  hasOverlay = false;
  hasOverlay2 = true;
  subjectMesh = null;
  subjectTextureResolution = 1024;
  envTexture = null;
  brushTexture = null;
  brushColorEnabled = true;
  brushColorHue = 1.0;
  brushColorVal = 0.5;
  brushColorSat = 0.5;
  brushColorAlpha = 1.0;
  brushPbrEnabled = true;
  brushPbrRoughness = 0.5;
  brushPbrMetalness = 0.0;
  brushBumpEnabled = true;
  brushBump = 0.1;
  brushRadius = 100;
}

class CMXOperation {
  _actions = [];

  subject = null;
  status = "idle";
  type = "unknown";

  constructor(type, subject) {
    this.startTime = Date.now();
    this.type = type;
    this.subject = subject;
    this.status = "inprogress";
  }

  update(action) {
    this._actions.push(action);
  }

  complete() {
    return this._actions.length == 0 && this.status == "complete";
  }

  commit() {
    // end this.operation
    this.status = "complete";
  }

  cancelled() {
    // end this.operation
    this.status = "cancelled";
  }

  *actions() {
    while (this._actions.length > 0) {
      yield this._actions[0];
      this._actions.shift();
    }
  }
}

class ActionPoint {
  constructor(timestamp, p, meta={}) {
    this.meta = meta;
    this.pos = p;
    this.startTime = timestamp;
    this.lastUpdateTime = timestamp;
    this.vel = null;
    this.distance = 0;
    this.duration = 0;
  }
  
  update(timestamp, p, meta) {
    let dt = (timestamp - this.lastUpdateTime);

    if (dt > 0.001) {
      if (p) {
        let dp = new THREE.Vector2().subVectors(p, this.pos)
        let v = dp.divideScalar(dt);

        this.vel = v;
        this.pos = p;
        this.distance += dp.length();
      }

      this.duration += dt;
      this.lastUpdateTime = timestamp;
    }

    this.meta = {...this.meta, ...meta};
  }
}

class ActionLine {
  constructor(a, b) {
    this.a = a;
    this.b = b;
    this.avgPos = null;
    this.avgVel = null;
    this.angle = null;
    this.length = null;
    this.dAngle = null;
    this.dLength = null;

    this.update();
  }

  update() {
    let diff = new THREE.Vector2().subVectors(this.a.pos, this.b.pos);
    let angle = diff.angle();
    let length = diff.length();

    if (this.angle && this.length) {
      this.dAngle = angle - this.angle;
      this.dLength = length - this.length;
    }

    this.angle = angle;
    this.length = length;

    this.avgPos = new THREE.Vector2().lerpVectors(this.a.pos, this.b.pos, 0.5);

    if (this.a.vel && this.b.vel) {
      this.avgVel = new THREE.Vector2().lerpVectors(this.a.vel, this.b.vel, 0.5);
    }
  }
}

// This is a bit of a dumpster fire - the assumption is that a lot of the UX code will be a bit entanged, so we want 
// to get this isolate.

// there is a lot of overlapping complexity with tracking state here (which fingers, buttons or keys are pressed) and 
// tracking state within the operators.
class PointerTracker {
  // new
  points = {};
  line = null;

  getPosition() {
    if (this.line) {
      return this.line.avgPos;
    }
    return this.first()?this.first().pos:null;
  }

  getVelocity() {
    if (this.line) {
      return this.line.avgVel;
    } 
    return this.first()?this.first().vel:null;
  }

  getAngle() {
    if (this.line) {
      return this.line.angle;
    } else {
      return 0.0;
    }
  }

  getLength() {
    if (this.line) {
      return this.line.length;
    } else { 
      return 0.0;
    }
  }

  getAngleDelta() {
    if (this.line) {
      return this.line.dAngle;
    } else {
      return 0.0;
    }
  }

  getLengthDelta() {
    if (this.line) {
      return this.line.dLength;
    } else { 
      return 0.0;
    }
  }
  
  getDistance() {
    return this.first()?this.first().distance:0.0;
  }

  getDuration() {
    return this.first()?this.first().duration:0.0;
  }

  // have we eliminated the possibility of a long press?  Basically any click that hasn't traveled X pixels within Y milliseconds
  // could be a long-press.  
  possiblyLongPress() {
    return (this.getDuration() < LONG_DURATION_MS && this.getDistance() < LONG_DISTANCE_PT);
  }

  first() {
    if (this.numPoints() > 0) {
      return Object.values(this.points)[0];
    } else {
      return null;
    }
  }

  numPoints() {
    return  Object.keys(this.points).length;
  }

  shift() {
    return (0 in this.points) && this.points[0].meta.shift;
  }

  ctrl() {
    return (0 in this.points) && this.points[0].meta.ctrl;
  }

  update(e) {
    if (e.type == "touchstart") {
      for (let t of e.touches) {
        this.points[t.identifier] = new ActionPoint(e.timeStamp, new THREE.Vector2(t.clientX,t.clientY));
      }

      if (this.numPoints() == 2) {
        this.onDoubleTouchUpgrade();
        let [a, b] = Object.values(this.points);
        this.line = new ActionLine(a, b);
      } else {
        this.onSingleTouchStart();
      }
    } 
    
    if (e.type == "mousedown") { 
      this.points[0] = new ActionPoint(e.timeStamp, new THREE.Vector2(e.clientX, e.clientY), {
        shift: e.shiftKey, 
        ctrl: e.ctrlKey,
      });

      if(this.shift() || this.ctrl()) {
        this.onMouseModStart();
      } else {
        this.onMouseStart();
      }
    }

    if (e.type == "touchend" || e.type == "touchcancel") {
      for (let t of e.touches) {
        this.points[t.identifier].update(e.timeStamp, null);
      }
      if (this.numPoints() == 2) {
        console.log("end double");
        this.onDoubleTouchEnd();
        this.line = null;
      } else if (this.numPoints() == 1) {
        this.onSingleTouchEnd();
      }
      this.points = {}
    }

    if (e.type == "mouseleave" || e.type == "mouseup") {
      0 in this.points && this.points[0].update(e.timeStamp, null);
      if (this.shift() || this.ctrl()) {
        this.onMouseModEnd();
      } else {
        this.onMouseEnd();
      }
      this.points = {}
    }

    if (e.type == "touchmove" && this.numPoints() > 0) {
      for (let t of e.touches) {
        this.points[t.identifier].update(e.timeStamp, new THREE.Vector2(t.clientX, t.clientY));
      }

      if (this.numPoints() == 2) {
        this.onDoubleTouchMove();
        this.line.update();
      } else {
        this.onSingleTouchMove();
      }
    }

    if (e.type == "mousemove" && this.numPoints() > 0) {
      this.points[0].update(e.timeStamp, new THREE.Vector2(e.clientX, e.clientY));
      
      if(this.shift() || this.ctrl()) {
        this.onMouseModMove();
      } else {
        this.onMouseMove();
      }
    }

    //if (this.numPoints() > 1) {
    //  console.log(JSON.stringify(this.line));
    //}
  }

  onSingleTouchStart = function(){};
  onSingleTouchMove = function(){};
  onSingleTouchEnd = function(){};
  onDoubleTouchUpgrade = function(){};
  onDoubleTouchMove = function(){};
  onDoubleTouchEnd = function(){};
  onMouseStart = function(){};
  onMouseMove = function(){};
  onMouseEnd = function(){};
  onMouseModStart = function(){};
  onMouseModMove = function(){};
  onMouseModEnd = function(){};
}

// eslint-disable-next-line no-unused-vars
export class CMXViewport {
    LONG_DURATION_MS = 200;
    LONG_DISTANCE_PT = 5;

    camera = null;
    light = null;
    scene = null;
    renderer = null;
    raycaster = null;

    material = null;
    mesh = null;
    recomputeUvBuffer = true;
    currentColor = new THREE.Color();

    constructor(root, component) {
      console.log(`attaching threejs to viewport...`);
      // general setup

      this.component = component;

      // tracking
      this.uvScale = new THREE.Vector2(1, 1);
      this.uvMouse = new THREE.Vector2();
      this.domMouse = new THREE.Vector2();
      this.mouse = new THREE.Vector2();

      this.lastUpdateTime = null;


      let aspect = window.innerWidth / window.innerHeight;

      // our camera (back a bit)
      this.camera = new THREE.PerspectiveCamera( 70, aspect, 0.01, 1000 );
      this.camera.position.z = 2;

      // stage our scene
      this.scene = new THREE.Scene();
      this.raycaster = new THREE.Raycaster();

      this.light = new THREE.DirectionalLight();
      this.light.color = new THREE.Color("#ffffdd");
      this.light.intensity = 1;
      this.light.castShadow =  true;
      this.light.position.x = 4;
      this.light.position.y = 4;
      this.light.position.z = 4;
      this.scene.add(this.light);
      
      this.blight = new THREE.DirectionalLight();
      this.blight.color = new THREE.Color("#ddddff");
      this.blight.intensity = 0.1;
      this.blight.castShadow =  true;
      this.blight.position.x = -4;
      this.blight.position.y = -4;
      this.blight.position.z = -4;
      this.scene.add(this.blight);

      // for picking, we render our UV (encoded due to webgl crap) so we can pick it right from
      // the buffer rather than casting rays w/ the CPU
      const uvShader = `
      varying vec2 v_uv;

      highp int modI(float a, float b) {
        float m=a-floor((a+0.5)/b)*b;
        return int(floor(m+0.5));
      }

      void main() {
        highp vec2  suv = v_uv * 65535.0;
        highp int u1 = int(suv.x) / 255;
        highp int u2 = modI(suv.x, 255.0);
        highp int v1 = int(suv.y) / 255;
        highp int v2 = modI(suv.y, 255.0);
        gl_FragColor = vec4(float(u1)/255.0, float(u2)/255.0, float(v1)/255.0, float(v2)/255.0).rgba;
      }`

      // TODO how do we efficient correct for UV distortion?
      //      can we get away w/ a simple linear transform?
      // TODO also want to scale brush appropriately
      //      can/should we combine this with the above?

      // composite the brush
      // TODO add previous frame as an input here
      const brushShader = `
      varying vec2 v_uv;

      uniform bool brushHide;
      uniform sampler2D brushTexture;
      uniform vec4 brushValue;

      void main() {
        if (brushHide) {
          discard;
        } else {
          //gl_FragColor = vec4(1,1,1,1);
          gl_FragColor = brushValue * texture2D(brushTexture, v_uv);
        }
      }`

      this.pickingMaterial = new THREE.ShaderMaterial({
        vertexShader: vShader,
        fragmentShader: uvShader
      });

      this.pickingTexture = new THREE.WebGLRenderTarget(1, 1);
      this.pickingTexture.setSize( window.innerWidth, window.innerHeight);

      this.posTexture = new THREE.WebGLRenderTarget(1, 1);
      this.posTexture.setSize( window.innerWidth, window.innerHeight);

      this.pixelBuffer = new Uint8Array(this.pickingTexture.width*this.pickingTexture.height*4); 

      this.material = new THREE.MeshStandardMaterial({ 
        flatShading: false,
        emissive: 1.0,
        emissiveIntensity: 10.0,
        bumpScale: 0.01,
        metalness: 1.0,
        roughness: 1.0,
        envMap: this.component.envTexture,
        envMapIntensity: 1.0,
      });

      this.compScene = new THREE.Scene();
      this.compCamera = new THREE.OrthographicCamera( -1, 1 , 1, -1, 0, 1 );

      this.compPlane = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: 0.0,
      }));
      this.compPlane.position.z = 0;

      let uniforms = {
        brushTexture: {value: null}, 
        brushPos: {value: { x: null, y: null }},
        brushValue: {value: new THREE.Vector4()},
        brushHide: {value: false}
      };
      this.brushMaterial = new THREE.ShaderMaterial({
        vertexShader: vShader,
        fragmentShader: brushShader, 
        transparent: true,
        uniforms
      });
      this.brushPlane = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), this.brushMaterial);
      this.brushPlane.position.z = 0;

      this.compScene.add(this.compCamera);
      this.compScene.add(this.compPlane);
      this.compScene.add(this.brushPlane);


      //this.renderStats = new Stats();
      //this.renderStats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
      this.renderer = new THREE.WebGLRenderer({ canvas: root, antialias: true });
      this.renderer.autoClear = false;
      this.renderer.setSize( window.innerWidth, window.innerHeight);
      //document.body.appendChild(this.renderStats.dom);
 
      this.renderer.setAnimationLoop((t) => { 
        //this.renderStats.begin();
        this.animation(t); 
        //this.renderStats.end();
      });

      // create our canvas-texture to paint to
      // undo stack
      this.history = new CMXUndoHistory([
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
        new CMXSurface(this.component.subjectTextureResolution),
      ], this.renderer);

      /*
      this.preview = new THREE.Mesh(new THREE.PlaneGeometry(0.4, 0.4), new THREE.MeshBasicMaterial({
        map: this.history.buffers[0].color.target.texture,
      }));
      this.preview.position.x = -aspect + 0.3;
      this.preview.position.y = -1.0 + 0.3 * 2.5;
      this.overlay.scene.add(this.preview);

      this.preview2 = new THREE.Mesh(new THREE.PlaneGeometry(0.4, 0.4), new THREE.MeshBasicMaterial({
        map: this.history.buffers[1].color.target.texture,
      }));
      this.preview2.position.x = -aspect + 0.3;
      this.preview2.position.y = -1.0 + 0.3 * 4;
      this.overlay.scene.add(this.preview2);
      */

      let tracker = new PointerTracker()
      this.renderer.domElement.addEventListener("touchstart", (e) => tracker.update(e), false);
      this.renderer.domElement.addEventListener("touchend", (e) => tracker.update(e), false);
      this.renderer.domElement.addEventListener("touchcancel", (e) => tracker.update(e), false);
      this.renderer.domElement.addEventListener("touchmove", (e) => tracker.update(e), false);
      this.renderer.domElement.addEventListener("mousedown", (e) => tracker.update(e), false);
      this.renderer.domElement.addEventListener("mouseup", (e) => tracker.update(e), false);
      this.renderer.domElement.addEventListener("mouseleave", (e) => tracker.update(e), false);
      this.renderer.domElement.addEventListener("mousemove", (e) => tracker.update(e), false);

      let toUv = (dom) => {
        let pr = this.renderer.getPixelRatio();
        let fw = this.renderer.getContext().drawingBufferWidth;
        let fh = this.renderer.getContext().drawingBufferHeight;
        let c = 4*(Math.round(dom.x*pr) + (fw*(fh - Math.round(dom.y*pr))));
        return new THREE.Vector2(
          (this.pixelBuffer[c+0] * 255 + this.pixelBuffer[c+1])/65535.0,
          (this.pixelBuffer[c+2] * 255 + this.pixelBuffer[c+3])/65535.0
        );
      }

      tracker.onMouseMove = tracker.onSingleTouchMove = () => {
        // try to order by frequency?
        this.domMouse = tracker.getPosition().clone();

        // use our pick buffer to transform screen space to uv space
        this.uvMouse = toUv(this.domMouse);

        let uvDx = 1000 * toUv(this.domMouse.add(new THREE.Vector2(-2, 0))).distanceTo(toUv(this.domMouse.add(new THREE.Vector2(2, 0))));
        let uvDy = 1000 * toUv(this.domMouse.add(new THREE.Vector2(0, -2))).distanceTo(toUv(this.domMouse.add(new THREE.Vector2(0, 2))));
        // unfortunately we can have discontinuities...
        if (uvDx < 3 && uvDy < 3) {
          this.uvScale.set(uvDx, uvDy);
        } else {
          //console.log(uvDx, uvDy);
        }

        if (this.operation) {

          // whether its a pick or paint, we update the operation
          this.operation.update({pos: this.uvMouse.clone()});

          // this is a bit wonky - its almost like the operation starts as many options, 
          // and collapses down over time
          if (this.operation.type == "pick-paint" && !tracker.possiblyLongPress()) {
            // TODO start painting operation
            // TODO this still paints any queued up updates from when we believed this could be 
            // a long press
            this.operation.type = "paint";
          }
        }
      };

      tracker.onMouseStart = tracker.onSingleTouchStart = () => {
        // try to order by frequency?
        this.domMouse = tracker.getPosition().clone();

        // use our pick buffer to transform screen space to uv space
        this.uvMouse = toUv(this.domMouse);

        let uvDx = 1000 * toUv(this.domMouse.add(new THREE.Vector2(-2, 0))).distanceTo(toUv(this.domMouse.add(new THREE.Vector2(2, 0))));
        let uvDy = 1000 * toUv(this.domMouse.add(new THREE.Vector2(0, -2))).distanceTo(toUv(this.domMouse.add(new THREE.Vector2(0, 2))));
        // unfortunately we can have discontinuities...
        if (uvDx < 3 && uvDy < 3) {
          this.uvScale.set(uvDx, uvDy);
        }


        // could be a long press (pick) or a paint - so this is ambiguous
        // TODO CMXOperation(["pick", "paint"], this.mesh)
        this.operation = new CMXOperation("pick-paint", this.mesh);

        // if it IS a paint, we don't want to lose these first points so we 
        // queue them up to be stroked later
        this.operation.update({pos: this.uvMouse.clone()});
      };

      tracker.onMouseEnd = tracker.onSingleTouchEnd = () => {
        if (this.operation) {
          // TODO this still paints any queued up updates from when we believed this could be 
          // this results from a "quick" click without moving the mouse, that didn't trip the long-press
          if (this.operation.type == "pick-paint") {
            this.operation.type = "paint";
          } 

          // wrap up the operation
          this.operation.update({pos: this.uvMouse.clone()});
          this.operation.commit();
        }
      }

      tracker.onMouseModStart = tracker.onDoubleTouchUpgrade = () => {
        this.operation = new CMXOperation("transform", this.mesh);
      }

      tracker.onMouseModEnd = tracker.onDoubleTouchEnd = () => {
        if (this.operation && this.operation.type == "transform") {
          if (tracker.possiblyLongPress()) {
            this.popState();
          } else {
            this.operation.commit();
            this.recomputeUvBuffer = true;
          }
        }
        this.operation.commit();
      }

      tracker.onMouseModMove = tracker.onDoubleTouchMove = () => {
        if (this.operation && this.operation.type == "transform" && tracker.getVelocity()) {
          // TODO modulate transforms 

          if (!tracker.ctrl() && !tracker.shift() && tracker.getAngleDelta() && tracker.getLengthDelta()) {
            this.operation.update({
              pan: tracker.getVelocity(),
              dr: tracker.getAngleDelta(),
              ds: tracker.getLengthDelta(),
            });
          }
          if (!tracker.ctrl()) {
            this.operation.update({
              pan: tracker.getVelocity(),
              dr: 0,
              ds: 0,
            });
          } else {
            this.operation.update({
              pan: new THREE.Vector2(0,0),
              dr: tracker.getVelocity().x * 0.05,
              ds: tracker.getVelocity().y,
            });
          }
        }
      }

      window.addEventListener('resize', () => {
        console.log("resizing!");
        // our cameras need to adjust for any change in the aspect size
        let aspect = window.innerWidth / window.innerHeight;
        this.camera.aspect = aspect;
        this.camera.updateProjectionMatrix();

        // and any textures/targets/buffers
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.pickingTexture.setSize(window.innerWidth, window.innerHeight);
        this.posTexture.setSize(window.innerWidth, window.innerHeight);
        this.pixelBuffer = new Uint8Array(this.pickingTexture.width*this.pickingTexture.height*4); 
        this.recomputeUvBuffer = true;

        // TODO overlay positions/alignment should be adjusted too
    }, false);
  }

  loadBrush(url) {
    console.log("loading brush from ", url);

    this.brushTexture = new THREE.TextureLoader().load(url);
    this.brushTexture.minFilter = THREE.NearestFilter;
    this.brushTexture.magFilter = THREE.NearestFilter;
    this.brushMaterial.uniforms.brushTexture.value = this.brushTexture;
  }

  loadObj(url) {
    console.log("loading obj from ", url);
    new OBJLoader2().load(url, (obj) => {
      console.log("loaded ", url);
      if (this.mesh) {
        this.scene.remove(this.mesh);
      }
      this.mesh = new THREE.Mesh(obj.children[0].geometry, this.material);
      this.scene.add( this.mesh );

      this.history.reset(this.renderer);
      this.pushState();
    }, null, function(error) {console.log(error);}, null);
  }

  pushState() {
    // push the current buffer into the undo stack
    // and get a new one
    this.history.push();
    this.updateState()
  }

  popState() {
    // pop the current buffer off the undo stack
    // and restore an old one
    this.history.undo();
    this.updateState()
  }

  updateState() {
    let current = this.history.current();
    let previous = this.history.previous();

    if (previous) {
      // we want to composite the previous image (on the "composite plane")
      // so that the new buffer reflects all the old stuff
      this.compPlane.material.transparent = true;
      this.compPlane.material.opacity = 1.0;

      // for this we don't render our brush (since we're initializing)
      this.brushPlane.material.uniforms.brushHide.value = true;

      current.color.render(this.renderer, this.compScene, this.compCamera, ()=> {
        this.compPlane.material.map = previous.color.target.texture;
        this.compPlane.material.needsUpdate=true;
      });

      current.pbr.render(this.renderer, this.compScene, this.compCamera, ()=> {
        this.compPlane.material.map = previous.pbr.target.texture;
        this.compPlane.material.needsUpdate=true;
      });

      current.bump.render(this.renderer, this.compScene, this.compCamera, ()=> {
        this.compPlane.material.map = previous.bump.target.texture;
        this.compPlane.material.needsUpdate=true;
      });
    }
    this.material.map = current.color.target.texture;
    this.material.metalnessMap = current.pbr.target.texture;
    this.material.roughnessMap = current.pbr.target.texture;
    this.material.bumpMap = current.bump.target.texture;
    this.material.needsUpdate = true;
  }

  colorPick(action) {
    if (!this.mesh) return;

    let uv = action.pos;

    let current = this.history.current();
    let _x = Math.floor(uv.x * current.color.target.width);
    let _y = Math.floor((1.0 - uv.y) * current.color.target.height);

    let color = current.color.pick(_x, _y);
    let pbr = current.pbr.pick(_x, _y);
    let bump = current.bump.pick(_x, _y);

    let hsl = {}
    color.getHSL(hsl);

    this.component.brushColorHue = hsl.h;
    this.component.brushColorSat = hsl.s;
    this.component.brushColorVal = hsl.l;

    this.component.brushPbrRoughness = pbr.g;
    this.component.brushPbrMetalness = pbr.b;
    this.component.brushBump = bump.r;
  }

  paint(action) {
    if (!this.mesh) return;
    if (!("pos" in action)) return; // WHY??

    let r = this.component.brushRadius;
    let rot = Math.random() * Math.PI * 2.0/10.0;

    let uv = action.pos;

    
    this.brushPlane.matrix.identity();
    this.brushPlane.rotation.z = rot;
    this.brushPlane.position.x = uv.x*2-1;
    this.brushPlane.position.y = uv.y*2-1;

    // dumb quadratic ramp thingy - the problem is we have so much overdraw to have 
    // a smooth stroke, that alpha saturates very quickly...
    let a = this.component.brushColorAlpha * this.component.brushColorAlpha  ;

    r = r*0.0008;
    //this.brushPlane.scale.set(r*this.uvScale.x, r*this.uvScale.y, 1);
    this.brushPlane.scale.set(r, r, 1);

    this.compPlane.material.transparent = true;
    this.compPlane.material.opacity = 0.0;
    this.brushPlane.material.uniforms.brushHide.value = false;

    this.currentColor.setHSL(
      this.component.brushColorHue,
      this.component.brushColorSat,
      this.component.brushColorVal,
    );

    let current = this.history.current();
    if (this.component.brushColorEnabled) {
      current.color.render(this.renderer, this.compScene, this.compCamera, ()=> {
        this.brushPlane.material.uniforms.brushValue.value.set(
          this.currentColor.r,
          this.currentColor.g,
          this.currentColor.b,
          a
        );
      });
    }

    if (this.component.brushPbrEnabled) {
      current.pbr.render(this.renderer, this.compScene, this.compCamera, ()=> {
        this.brushPlane.material.uniforms.brushValue.value.set(
          0.0,
          this.component.brushPbrRoughness,
          this.component.brushPbrMetalness,
          a
        );
      });
    }

    if (this.component.brushBumpEnabled) {
      current.bump.render(this.renderer, this.compScene, this.compCamera, ()=> {
        this.brushPlane.material.uniforms.brushValue.value.set(
          this.component.brushBump,
          this.component.brushBump,
          this.component.brushBump,
          a
        );
      });
    }
  }

  toPng() {
  }
    
  transform(action) {
    if (!this.mesh) return;
    if (!("pan" in action)) return; // WHY??

    let scaleFactor = 0.006;
    let rotFactor = 0.05;

    // to become transform manipulator
    let transform = (obj, rx, ry, rz, zoom) => {
      if (!obj) return;
      //console.log(rx, ry, rz);
      obj.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), rx);
      obj.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), ry);
      obj.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), rz);
      obj.scale.addScalar(zoom);
    };

    transform(this.mesh, action.pan.y*rotFactor, action.pan.x*rotFactor, -action.dr, action.ds*scaleFactor);
  }

  animation( time ) {
    // operation "dispatch"
    // this.operation && console.log(this.operation);
    // if we started but haven't moved the mouse, initiate color picking
    if(this.operation && this.operation.type == "pick-paint") {
      if ((Date.now() - this.operation.startTime) > LONG_DURATION_MS) {
        this.operation.type = "pick";

        // update our pick buffers
        let buffers = this.history.current();
        buffers.color.recompute = true;
        buffers.pbr.recompute = true;
        buffers.bump.recompute = true;
        this.updateState();
      }
    }

    // this is basically like ticking a bunch of lil systems
    if(this.operation && this.operation.type == "paint") {
      // TODO begin

      for (let p of this.operation.actions()) {
        this.paint(p);
      }

      // TODO end
      if (this.operation.complete()) {
        this.pushState()
      }
    }

    if(this.operation && this.operation.type == "transform") {
      for (let p of this.operation.actions()) {
        this.transform(p);
      }
    }
    
    if(this.operation && this.operation.type == "pick") {
      this.renderer.setClearColor(this.currentColor, 0.2);
      for (let p of this.operation.actions()) {
        this.colorPick(p);
      }
    }
    
    if (this.operation && this.operation.complete()) {
      this.renderer.setClearColor("#101010");
      console.log("operation finished - resetting");
      this.operation = null;
    }

    // don't bother until we have our mesh loaded...
    if (this.mesh == null) {
      //console.log("still no mesh after t=", time);
      time;
      return;
    }

    // render our scene and ortho overlay
    {
      this.renderer.setRenderTarget(null);
      this.renderer.setClearColor("#101010");
      this.renderer.clear();
      this.mesh.material = this.material;
      this.renderer.render( this.scene, this.camera );
    }

    if (this.recomputeUvBuffer) {
      console.log("recomputing our UV buffer!");

      // Render w/ our "pick" pass
      this.mesh.material = this.pickingMaterial;

      this.renderer.setRenderTarget(this.pickingTexture);
      this.renderer.clear();
      this.renderer.render( this.scene, this.camera );
      this.renderer.readRenderTargetPixels(
          this.pickingTexture,
          0,   // x
          0,   // y
          this.renderer.getContext().drawingBufferWidth,
          this.renderer.getContext().drawingBufferHeight,
          this.pixelBuffer);

      this.recomputeUvBuffer = false;
    }
  }
}
